/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "./Sidebar.css";
import Header from "./Header";
import {
  getBankDetails,
  leadWallet,
  createTransaction,
} from "../Redux/ApiCall/action";

import Side from "./Side";
import { resolve } from "q";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Loader } from "./Loader";
const cookies = new Cookies();

const options = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 3000,
};

const Withdrawl = (props) => {
  const token = cookies.get("token");
  const [amount, setAmount] = useState(0);
  const [bank, setBank] = useState();
  const [account, setAccount] = useState();
  const [withdrawamount, setWithdrawamount] = useState("");
  const [openrequest, setOpenrequest] = useState("");
  const [erramount, setErramount] = useState("");
  const [message, setMessage] = useState("");
  const [bankDetailStatus, setBankDetailStatus] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(true);
  const [amountloader, setAmountloader] = useState(true);
  const [isDisabled, setIsdisabled] = useState(true);

  useEffect(() => {
    if (!cookies.get("token")) {
      props.history.push({ pathname: "/" });
      return;
    }
    hanleLeadWallet();
    handleBankDetails();
  }, []);

  const hanleLeadWallet = () => {
    props
      .leadWallet(token)
      .then((res) => {
        if (res.status === 401) {
          props.history.push("/");
        }
        setAmountloader(false);
        setAmount(res.data.data.balance);
        setOpenrequest(res.data.data.open_request);
      })
      .catch((error) => {
        let err = error?.response?.status;

        if (err === 401) {
          props.history.push("/");
        }

        // Notification(error)
      });
  };
  const handleBankDetails = () => {
    props
      .getBankDetails(token)
      .then((res) => {
        setLoader(false);
        setBank(res.data.data[0].bank_name);
        setAccount(res.data.data[0].account_number);
        setBankDetailStatus(res.data.data[0].verified);
      })
      .catch((error) => {
        setLoader(false);
        let err = error?.response?.data?.error;

        setErramount(err);
      });
  };

  const notify = (err) => {
    return toast.error(err, { ...options });
  };
  const toastId = React.useRef(null);

  const createTransaction = () => {
    if (withdrawamount === "") {
      setErramount("Enter Amount");
      return;
    }
    if (bankDetailStatus) {
      if (bankDetailStatus !== "VERIFIED") {
        setError("Kindly verify your bank details in PayMe App");
      }

      let amount = Number(withdrawamount);
      const token = cookies.get("token");
      let data = {
        amount,
      };
      setLoader(true);
      props
        .createTransaction(data, token)
        .then((res) => {
          setLoader(false);
          if (res.status === 200) {
            props.leadWallet(token).then((res) => {
              toast.success(
                "Your withdrawal request has been created successfully",
                { ...options }
              );
              setAmount(res.data.data.balance);
              setOpenrequest(res.data.data.open_request);
            });
          }
        })
        .catch((error) => {
          setLoader(false);
          let err = error?.response?.data?.error;
          notify(err);

          // Notification(error)
        });
    } else {
      setError("Kindly Upload your bank details in PayMe App");
    }
  };

  return (
    <>
      {loader ? <Loader /> : null}
      <Header {...props} />
      <Side {...props} />
      <div className="wrapper">
        <div className="backPage">Withdrawal</div>
        <div className="bothDivAlign">
          <div className="leftSectionDashboard">
            <Card
              className="cardShadow contentText"
              style={{ minHeight: 260, margin: 0 }}
            >
              <Card.Body style={{ padding: 30, paddingBottom: 0 }}>
                <Card.Text className="labelReferral">
                  <strong>Referral</strong>
                </Card.Text>
                {/* <p className="textNormal">Last Withdraw on 23 July</p> */}{" "}
                <div className="greyBoxLarge">
                  <p>Available Balance In Wallet</p>
                  {!amountloader ? (
                    <h4>₹ {amount} </h4>
                  ) : (
                    <>
                      <SkeletonTheme color="#152745" highlightColor="#ffffff">
                        <h4>
                          ₹ <Skeleton width={60} />
                        </h4>
                      </SkeletonTheme>
                    </>
                  )}
                </div>
                <div className="greyBoxLargeBorder">
                  <span className="errorMessage">{message}</span>
                  <p>Request Withdraw Amount</p>
                  <span className="rupeesIcon">₹</span>
                  <input
                    className="inputBox"
                    type="number"
                    placeholder=" Enter Amount"
                    id="withdrawamount"
                    name="withdrawamount"
                    value={withdrawamount}
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        setErramount("");
                        setMessage("");
                      }

                      setWithdrawamount(e.target.value.slice(0, 6));
                    }}
                  />
                  <br />
                  <span className="errorMessage">{erramount}</span>
                  <span className="errorMessage">{error}</span>
                  <div className="borderText">
                    {!loader ? (
                      <>
                        {bank ? (
                          <>
                            Linked with {bank} ending with{" "}
                            {account?.slice(account.length - 4)}
                          </>
                        ) : (
                          <span>Upload Your Bank Details</span>
                        )}
                      </>
                    ) : (
                      <>
                        <SkeletonTheme color="#152745" highlightColor="#ffffff">
                          <Skeleton />
                        </SkeletonTheme>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="greyBoxLargeBorder"
                  style={{ textAlign: "left", marginBottom: 20 }}
                >
                  <p>Open Request</p>

                  <Card.Text
                    style={{
                      display: "flex",
                      fontSize: "2rem",
                      fontWeight: "700",
                      fontFamily: "Montserrat",
                      marginBottom: 30,
                      justifyContent: "center",
                    }}
                  >
                    {openrequest}
                  </Card.Text>
                </div>
                <Button
                  className="btn btn-green"
                  onClick={(e) => {
                    if (isDisabled) {
                      setIsdisabled(false);
                      createTransaction();
                      setTimeout(() => {
                        setIsdisabled(true);
                      }, 3000);
                    }
                  }}
                >
                  Request Withdraw
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="rightSectionDashboard">
            <Card
              className="cardShadow contentText"
              style={{
                minHeight: 260,
                margin: 0,
                height: "100%",
                marginBottom: 30,
              }}
            >
              <Card.Body style={{ padding: 30 }}>
                <Card.Text className="labelReferral">
                  <strong>Note</strong>
                </Card.Text>
                <ul className="bulletPoint">
                  <li>
                    The minimum withdrawal amount must be Rs. 50. There is no
                    maximum limit.
                  </li>

                  <li>
                    To receive the cash rewards, The referee’s account must be
                    ‘verified’ on PayMe App.
                  </li>

                  <li>
                    Withdrawal balance would be updated within 24 hours of
                    successful verification of referee’s account.
                  </li>

                  <li>
                    To refer someone, it is compulsory to sign up with PayMe
                    India app.
                  </li>

                  <li>
                    You can transfer the cash rewards to your bank account only
                    once it is verified by PayMe India.
                  </li>

                  <li>
                    Once you initiate the transfer request to your account, the
                    request will be opened, and the status would be ‘Yes’. The
                    requested fund will be credited to your banking account with
                    24 hours.
                  </li>

                  <li>You can request only one withdrawal at time.</li>

                  <li>
                    Once the withdrawal request is processed by PayMe, the open
                    request status will be ‘NO’ and you can again initiate the
                    request of withdrawal.
                  </li>
                  <li>
                    In order to withdraw the amount, kyc and bank details need
                    to be submitted and verified by us (verification takes
                    maximum 24 working hours, hence we recommend using eKYC
                    option).
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  leadWallet,
  getBankDetails,
  createTransaction,
})(Withdrawl);
