import {React,useState} from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import WithdrawalIcon from "@material-ui/icons/Redeem";
import GroupIcon from "@material-ui/icons/Group";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import HelpIcon from "@material-ui/icons/Help";
import PolicyIcon from "@material-ui/icons/Policy";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {slide as Menu} from 'react-burger-menu'


const Side = (props) => {
   
   const path = window.location.pathname;
    


    return (
        <>
            <Menu>
                <div className="sidebar_page">
                    <div className="dashboard_option">
                        <Link className="active_class" to="/dashboard">
                            <DashboardIcon className={`dashboard_icon ${ path==="/dashboard"?"active_class":""}`}  />
                            <span className={`dashboard_span ${ path==="/dashboard"?"active_class":""}`} >Dashboard</span>
                          
                        </Link>
                        <Link className="active_class" to="/leads">
                            <GroupIcon className={`dashboard_icon ${ path==="/leads"?"active_class":""}`} style={{top: "55px"}}/>
                            <span className={`dashboard_span ${ path==="/leads"?"active_class":""}`} style={{top:"58px"}}>Leads</span>
                        </Link>
                        <Link className="active_class" to="/transactions">
                            <EqualizerIcon className={`dashboard_icon ${ path==="/transactions"?"active_class":""}`} style={{top: "111px"}}/>
                            <span className={`dashboard_span ${ path==="/transactions"?"active_class":""}`} style={{top: "115px"}}>Earnings</span>
                        </Link>
                        <Link className="active_class" to="/withdrawal">
                            <WithdrawalIcon className={`dashboard_icon ${ path==="/withdrawal"?"active_class":""}`} style={{top: "171px"}}/>
                            <span className={`dashboard_span ${ path==="/withdrawal"?"active_class":""}`} style={{top: "175px"}}>Withdrawal</span></Link>

                        <Link className="active_class" to="/faq">
                            <HelpIcon className={`dashboard_icon ${ path==="/faq"?"active_class":""}`} style={{top: "225px"}}/>
                            <span className={`dashboard_span ${ path==="/faq"?"active_class":""}`} style={{top: "229px"}}>Help</span>
                        </Link>
                        <Link className="active_class" to="/terms-conditions">
                            <PolicyIcon className={`dashboard_icon ${ path==="/terms-conditions"?"active_class":""}`} style={{top: "275px"}}/>
                            <span className={`dashboard_span ${ path==="/terms-conditions"?"active_class":""}`} style={{top: "279px"}}>T & C</span>
                        </Link>
                    </div>
                </div>
            </Menu>
            <div className="sidebar_page">
                <div className="dashboard_option">
                    <Link className="active_class" to="/dashboard"  >
                     
                        <DashboardIcon className={`dashboard_icon ${ path==="/dashboard"?"active_class":""}`} />
                        <span className={`dashboard_span ${ path==="/dashboard"?"active_class":""}`} >Dashboard</span>
                        
                       
                    </Link>
                    <Link className="active_class" to="/leads">
                        <GroupIcon className={`dashboard_icon ${ path==="/leads"?"active_class":""}`} style={{top: "55px"}}/>
                        <span className={`dashboard_span ${ path==="/leads"?"active_class":""}`} style={{top: "58px"}}>Leads</span>
                    </Link>
                    <Link className="active_class" to="/transactions">
                        <EqualizerIcon className={`dashboard_icon ${ path==="/transactions"?"active_class":""}`} style={{top: "111px"}}/>
                        <span className={`dashboard_span ${ path==="/transactions"?"active_class":""}`} style={{top: "115px"}}>Earnings</span>
                    </Link>
                    <Link className="active_class" to="/withdrawal">
                        <WithdrawalIcon className={`dashboard_icon ${ path==="/withdrawal"?"active_class":""}`} style={{top: "171px"}}/>
                        <span className={`dashboard_span ${ path==="/withdrawal"?"active_class":""}`} style={{top: "175px"}}>Withdrawal</span></Link>

                    <Link className="active_class" to="/faq">
                        <HelpIcon className={`dashboard_icon ${ path==="/faq"?"active_class":""}`} style={{top: "225px"}}/>
                        <span className={`dashboard_span ${ path==="/faq"?"active_class":""}`} style={{top: "229px"}}>Help</span>
                    </Link>
                    <Link className="active_class" to="/terms-conditions">
                        <PolicyIcon className={`dashboard_icon ${ path==="/terms-conditions"?"active_class":""}`} style={{top: "275px"}}/>
                        <span className={`dashboard_span ${ path==="/terms-conditions"?"active_class":""}`} style={{top: "279px"}}>T & C</span>
                    </Link>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(Side);
