/* eslint-disable*/
import { React, useState, useEffect } from "react";
import { Container, Card, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { sendOtp, saveUserToken } from "../Redux/ApiCall/action";
import { verifyOtp } from "../Redux/ApiCall/action";
import Resetmpin from "./Resetmpin";
import logo from "../img/logo.png";
import "./Sidebar.css";
import OtpInput from "react-otp-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Loader } from "./Loader";

const options = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 5000,
};


const Confirmotp = (props) => {
  const [otp_get, setOtp] = useState("");
  const [phone, setPhone] = useState(props.phone_number.phone_number);
  const [message, setMessage] = useState("");
  const [resetMpin, setResetMpin] = useState(false);
  const [counter, setCounter] = useState(59);
  const [toggle,setToggle]=useState("")
  const[loader,setLoader]=useState(false)


  useEffect(() => {

    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);


  const handleResend = () => {
    setLoader(true)
    setCounter(59)
    setOtp("");
    let phone_number = phone;
    let data = {
      phone_number,
    };
    props
      .sendOtp(data)
      .then((res) => {
        setLoader(false)
        if (res.status === 200) {
          setMessage(res.data.message);
        }
      })
      .catch((error) => {
        setLoader(false)
        let err = error?.response?.data?.error;
        notify(err);
      });
  };

  const notify = (err) => {
    return toast.error(err, { ...options });
  };

  const handleVerificationOtp = (e) => {
    let phone_number = phone;
    let otp = e;
    if (e?.target?.value.length < 4) {
      notify("Please Enter 4 digit OTP");
      return;
    }
    let forget_password = true;
    let data = {
      phone_number,
      otp,
      forget_password,
    };
    setLoader(true)
    props
      .verifyOtp(data)
      .then((res) => {
        setLoader(false)
        if (res.status===200) {
          
          let token = res.data.data.token;
          props.saveUserToken({
            token,
          });
          setResetMpin(true);
        }
      })
      .catch((error) => {
        setLoader(false)
        let err = error?.response?.data?.error;
        notify(err);
        // Notification(error)
      });
  };



  return (
    <>
      {loader ? (<Loader />):null}
      {resetMpin ? (
        <Resetmpin {...props} />
      ) : (
          <div className='blue-bg'>
           
            <Card className='loginScreen'>
              <Card.Body className='loginScreenPadding'>
                <Link to="/">
                  <Card.Img
                    style={{
                      width:'auto',
                      height: 37,
                      margin: "auto",
                    }}
                    src="https://wwwstatic-prod.s3-accelerate.amazonaws.com/images/logo.svg"
                    alt="Paymeindia"
                  />
                </Link>
                <Card.Title  className='labelTitleBox'>
                  OTP for Password Change
                </Card.Title>
                <label htmlFor="userid" className='labelText' style={{textAlign:'center'}}>
                  Verification Code has been sent to +91XXXXXX{phone.slice(6,10)}
                </label>
                <label htmlFor="userid" className='labelText' style={{textAlign:'center'}}>
                  {message}
                </label>
                <br />
                <Card.Text  className='labelTitleBox'>
                  00: {counter>9 ? <span>{counter}</span> : <span>0{counter}</span>}
                </Card.Text>

                <label htmlFor="userid" className='labelText' style={{textAlign:'center'}}>
                  Enter 4 digit Verification Code
                </label>


                <div
                  style={{
                    paddingTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <OtpInput
                    numInputs={4}
                    isInputNum={true}
                    inputStyle={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "15%",
                    }}
                    className="otp-input"
                    focusStyle={{
                      color: "#152745",
                      borderColor: "#ff5700",
                      borderRadius: "20%",
                    }}
                    value={otp_get}
                    onChange={(event) => {
                      setOtp(event);
                      if (event.length === 4) {
                        handleVerificationOtp(event);
                      }
                    }}
                  />
                </div>

                <Button
                
                  className="btnNormal btn btn-primary"
                  style={{
                    marginBottom: "5%",
                    marginTop: "7%",
                  }}
                >
                  Verify Now
                </Button>
                {counter === 0 ? (
                    <Link to="#" onClick={handleResend}>
                      <label htmlFor="userid" className='labelText' style={{textAlign:'center',cursor:"pointer",color:"#FC6603"}}>
                        Resend
                      </label>
                    </Link>
                 
                ) : (
                    <label className='labelText' style={{textAlign:'center'}}>
                    Resend
                    </label>
                )}
              </Card.Body>
            </Card>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    phone_number: state.user,
  };
};

export default connect(mapStateToProps, { sendOtp, verifyOtp, saveUserToken })(
  Confirmotp
);
