/* eslint-disable*/
import {React, useState, useEffect} from "react";
import {connect} from "react-redux";
import {getUserDetails, getUserProfie} from "../Redux/ApiCall/action";  
import {Link} from "react-router-dom";
import "./Header.css";
import logo from "../img/logo.png";
import {Card} from "react-bootstrap";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import PersonIcon from '@material-ui/icons/Person';
import Cookies from 'universal-cookie';
import Skeleton,{SkeletonTheme} from 'react-loading-skeleton';
 
const cookies = new Cookies();

const Header = (props) => {
    const [profile, setProfile] = useState("");
    const [fname, setFname] = useState("");
    const [sname, setSname] = useState("");
    const [togglelogout, setTogglelogout] = useState(false);
    const [loader,setLoader]=useState(true);

    const handleLogout =()=>{
      
       cookies.remove('token', { path: '/' })
        props.history.push({ pathname: "/" });
    
    }
    useEffect(() => {
        const token = cookies.get('token');
        props.getUserDetails(token).then((res) => {
          
          
         
            setFname(res.data.data[0].first_name);
            setSname(res.data.data[0].last_name)
            if(res.data.data[0]){
            setLoader(false);
            }
        })
        .catch((error) => {
            let err = error.response.status;
        
            if(err===401)
            {
              props.history.push("/")
            }});;

        props.getUserProfie(token).then((res) => {
            setProfile(res.data.presigned_url);
           
        });
    }, []);

    const handleToggle = () => {
        setTogglelogout(
            (togglelogout) => !togglelogout)
    }

    return (
        <>
            <nav className="navbar">
                <Link to="/dashboard">
                    <img height="37" src="https://wwwstatic-prod.s3-accelerate.amazonaws.com/images/logo.svg" alt="paymeindia"/>
                </Link>
                <div className="navbar_right">
                    {/* <NotificationsIcon className="header_icon"/> */}
                    <Link to="#" onClick={handleToggle}>
                        { profile? <img className="profile_icon" src={profile} alt="profile"/> : <PersonIcon style={{width:38, height:38, color:'#152745'}} className="profile_icon" />}
                       {loader? <><SkeletonTheme color="#152745" highlightColor="#ffffff"><Skeleton width={120} height={20} className="loadername"/></SkeletonTheme></> :(<span className="profile_name">{fname}{" "}{sname}</span>)}
                    </Link>
                    {togglelogout ?
                        <Card className='dropDownMenu'>
                            <Link to="/profile">
                                <span><PersonIcon className="logout_icon"/>View Profile</span>
                            </Link>
                            <Link to= "#" onClick={handleLogout}>
                                <span><PowerSettingsNewIcon className="logout_icon"/>Logout</span>
                            </Link>
                        </Card>
                        : null}
                </div>
            </nav>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, {getUserDetails, getUserProfie})(
    Header
);
