/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../Sidebar.css";
import Header from "../Header";
import { getLeads } from "../../Redux/ApiCall/action";

import Side from "../Side";
import filter from "../../img/filter.svg";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Dropdown from "react-bootstrap/Dropdown";
import CalenderIcon from "@material-ui/icons/Today";
import Cookies from "universal-cookie";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import moment from 'moment';


const cookies = new Cookies();

const Leads = (props) => {
  const [leaddata, setData] = useState([]);
  const [phone, setPhone] = useState(props.user.phone_number);
  const [enddate, setEnddate] = useState("");
  const [filteroption, setFilteroption] = useState("All Time");
  const [checkboxSuccess, setCheckboxSuccess] = useState(false);
  const [checkboxPending, setCheckboxPending] = useState(false);
  const [loader, setLoader] = useState(true);
  const [tstasuctus, setTstatus] = useState("");
  const [success, setSuccess] = useState("");
  const [pending, setPending] = useState("");
  const [common, setCommon] = useState("");
  const [filterdata, setFilterdata] = useState("");
  const handleFilter = (e) => {
    setEnddate(e);
    if(success==="True" && pending===""){
      setCommon("True")
      
      }
      if(success==="" && pending==="False")
      {
        setCommon("False");
       
      }
      if(success==="" && pending==="")
      {
        setCommon("");
      }
  };

  const handleCheckboxSuccess = () => {
    setLoader(true);
    let token = cookies.get("token");
    setSuccess("True");
    
    let data = {
      end_date: filterdata.end_date,
      start_date: filterdata.start_date,
      can_apply_filter: "True",
    };
    if (checkboxSuccess === true) {
      setSuccess("");
      data = {
        end_date: filterdata.end_date,
        start_date: filterdata.start_date,
        can_apply_filter: "",
      };
    }
    setCheckboxSuccess((enabledCheckBox) => !enabledCheckBox);
console.log("data",data)
    props.getLeads(data, token).then((res) => {
      if (res.status === 401) {
        props.history.push("/");
      }
      setLoader(false);
      setData(res.data.data);
    });
  };

  const handleCheckboxPending = () => {
    setLoader(true);
    let token = cookies.get("token");
    setPending("False");
    let data = {
      end_date: filterdata.end_date,
      start_date: filterdata.start_date,
      can_apply_filter: "False",
    };
    if (checkboxPending === true) {
      setPending("");
      data = {
        end_date: filterdata.end_date,
        start_date: filterdata.start_date,
        can_apply_filter: "",
      };
    }
    setCheckboxPending((enabledCheckBox) => !enabledCheckBox);
   console.log("data2",data)
    props.getLeads(data, token).then((res) => {
      setLoader(false);
      if (res.status === 401) {
        props.history.push("/");
      }
      setData(res.data.data);
    });
  };

  var date = new Date().toISOString().slice(0, 10);
  var d = new Date();
  var previousYearDate = `${d.getFullYear()}-${1}-${1}`;
  var last_month = `${d.getFullYear()}-${d.getMonth()}-${1}`;
  var this_month = `${d.getFullYear()}-${d.getMonth() + 1}-${1}`;
  var lastpreviousYearDate = `${d.getFullYear() - 2}-${1}-${1}`;

  var now = new Date();
  var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  var lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
  
  var this_week = `${lastSunday.getFullYear()}-${
    lastSunday.getMonth() + 1
  }-${lastSunday.getDate()}`;
  var last_week = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
  var last_week_end = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
  


   

  useEffect(() => {
    const token = cookies.get("token");

    if (!cookies.get("token")) {
      props.history.push({ pathname: "/" });
      return;
    }




    if (enddate === this_month) {
      setLoader(true);
      var data = {
        end_date: date,
        start_date: enddate,
        can_apply_filter:common,
      };
      setFilteroption("This Month");
    }
    if (enddate === last_month) {
      setLoader(true);
      var data = {
        end_date: this_month,
        start_date: enddate,
        can_apply_filter:common,
      };
      setFilteroption("Last Month");
    }
    if (enddate === this_week) {
      setLoader(true);
      var data = {
        end_date: date,
        start_date: enddate,
        can_apply_filter:common,
      };
      setFilteroption("This Week");
    }
    if (enddate === last_week) {
      console.log("thisshsisi",last_week)
      setLoader(true);
      var data = {
        end_date: last_week_end,
        start_date: enddate,
        can_apply_filter:common,
      };
      setFilteroption("Last Week");
    }
    if (enddate === previousYearDate) {
      setLoader(true);
      var data = {
        end_date: date,
        start_date: enddate,
        can_apply_filter:common,
      };
      setFilteroption("This Year");
    }
    if (enddate === lastpreviousYearDate) {
      setLoader(true);
      var data = {
        end_date: previousYearDate,
        start_date: enddate,
        can_apply_filter:common,
      };
      setFilteroption("Last Year");
    }
    if (enddate === "") {
      setLoader(true);
      var data = { can_apply_filter:common};
      setFilteroption("All Time");
    }
    setFilterdata(data);
  console.log("data3",data)
    props
      .getLeads(data, token)
      .then((res) => {
        setLoader(false);
        setData(res.data.data);
      })
      .catch((error) => {
        let err = error.response.status;

        if (err === 401) {
          props.history.push("/");
        }
      });
  }, [enddate]);

  const products = leaddata;

  const columns = [
    {
      dataField: "created_at",
      text: "Date Created",
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell !== "object") {
          dateObj = new Date(cell);
        }
        return `${("0" + dateObj.getDate()).slice(-2)}/${(
          "0" +
          (dateObj.getMonth() + 1)
        ).slice(-2)}/${dateObj.getFullYear()}`;
      },
    },
    {
      dataField: "first_name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "can_apply_for_loan",
      text: "Document Status",
      sort: true,
    },
  ];
console.log(common)
  return (
    <>
      <Header {...props} />
      <Side />
      <div className="wrapper">
        <div style={{ position: "absolute", top: 10, right: 20 }}>
          <Dropdown align="end" className="filterName">
            <Dropdown.Toggle variant="btn">
              <img
                className="pointer"
                src={filter}
                alt="filter"
                style={{ marginTop: -4 }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="filterStatus">
              <div className="filterHead">Filter By Status</div>
              <label htmlFor="success">
                <input
                  type="checkbox"
                  id="success"
                  onChange={handleCheckboxSuccess}
                  value="Monday"
                  disabled={checkboxPending}
                />{" "}
                Verified
              </label>
              <label htmlFor="pending">
                <input
                  type="checkbox"
                  id="pending"
                  onChange={handleCheckboxPending}
                  value="Monday"
                  disabled={checkboxSuccess}
                />{" "}
                Pending
              </label>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown onSelect={handleFilter} align="end" className="filterMonth">
            <Dropdown.Toggle variant="btn" className="monthFilter">
              <CalenderIcon className="iconColor" />
              {filteroption}
            </Dropdown.Toggle>

            <Dropdown.Menu onSelect={handleFilter} className="dropDownFont">
              <Dropdown.Item eventKey={this_month}>
                {" "}
                <CalenderIcon className="calanderIcon" /> This Month
              </Dropdown.Item>

              <Dropdown.Item eventKey={this_week}>
                <CalenderIcon className="calanderIcon" /> This Week
              </Dropdown.Item>
              <Dropdown.Item eventKey={last_week}>
                <CalenderIcon className="calanderIcon" /> Last Week
              </Dropdown.Item>
              <Dropdown.Item eventKey={last_month}>
                <CalenderIcon className="calanderIcon" /> Last Month
              </Dropdown.Item>
              <Dropdown.Item eventKey={previousYearDate}>
                <CalenderIcon className="calanderIcon" /> This Year
              </Dropdown.Item>
              <Dropdown.Item eventKey={lastpreviousYearDate}>
                <CalenderIcon className="calanderIcon" /> Last Year
              </Dropdown.Item>
              <Dropdown.Item eventKey="">
                <CalenderIcon className="calanderIcon" /> All Time
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="backPage"> All Leads</div>
        <Card className="cardShadow widthFull tabularGrid">
          <Card.Body style={{ padding: 30 }}>
            {!loader ? (
              <div className="tabularData">
                <BootstrapTable
                  keyField="id"
                  data={products}
                  columns={columns}
                  pagination={paginationFactory()}
                />
              </div>
            ) : (
              <Skeleton count={15} />
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { getLeads })(Leads);
