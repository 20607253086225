/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../Sidebar.css";
import Header from "../Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  leadWallet,
  getRevenueLeads,
  getReferralCode,
  getUserDetails,
} from "../../Redux/ApiCall/action";
import copy from "../../img/copy.svg";
import Side from "../Side";
import { Link } from "react-router-dom";
import lottie from "lottie-web";
import animateRewaed from "../../img/animateJson/rewards.json";
import Cookies from "universal-cookie";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const cookies = new Cookies();
toast.configure();
const options = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 4000,
  limit: 1,
  closeButton: false,
};

const Dashboard = (props) => {
  const [revenue, setRevenue] = useState("0");
  const [leads, setLeads] = useState("0");
  const [loader, setLoader] = useState(true);
  const [referallink, setReferallink] = useState("");
  const [referalCode, setReferalCode] = useState("");
  const [loadercode, setLoaderrefcode] = useState(true);
  const textAreaRef = useState(null);
  const textAreaRefCode = useState(null);
  const [togglecode, setTogglecode] = useState(true);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#animationRewaed"),
      animationData: animateRewaed,
      renderer: "svg", // "canvas", "html"
      loop: true, // boolean
    });
    const token = cookies.get("token");

    if (!cookies.get("token")) {
      props.history.push({ pathname: "/" });
      return;
    }
    let data = "";

    props
      .getUserDetails(token)
      .then((res) => {
        if (res) {
          setLoader(false);
        }

        setReferallink(res.data.data[0].referral_link);
      })
      .catch((error) => {
        let err = error.response.status;

        if (err === 401) {
          props.history.push("/");
        }
      });
    props.getReferralCode(token).then((res) => {
      setLoaderrefcode(false);
      setReferalCode(res.data.referral_code);
    });

    props
      .getRevenueLeads(data, token)
      .then((res) => {
        if (res.status === 401) {
          props.history.push("/");
        }
        setRevenue(res.data.data.revenue);
        setLeads(res.data.data.leads);
        setLoader(false);
      })
      .catch((error) => {
        let err = error.response?.status;

        if (err === 401) {
          props.history.push("/");
        }
      });
  }, []);
  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");

    e.target.focus();
    if (togglecode) {
      toast.success("Copied!", { ...options });
      setTogglecode(false);
      setTimeout(() => setTogglecode(true), 3000);
    }
  }

  function copyToClipboardCode(e) {
    textAreaRefCode.current.select();
    document.execCommand("copy");

    e.target.focus();
    if (togglecode) {
      toast.success("Copied!", { ...options });

      setTogglecode(false);
      setTimeout(() => setTogglecode(true), 3000);
    }
  }

  // const decoratedOnClick = useAccordionButton(eventKey, onClick);
  return (
    <>
      <Header {...props} />
      <Side />
      <div className="wrapper">
        <div className="backPage">Dashboard</div>
        <div className=" widthFull">
          <div className=" align-parallel widthFull">
            <Card className="cardShadow widthFull marginRight15">
              <Card.Body>
                <Card.Text className="labelReferral">
                  <strong>Revenue Generated</strong>
                </Card.Text>
                <div className="greyBox">
                  {!loader ? (
                    <h4>₹ {revenue}</h4>
                  ) : (
                    <SkeletonTheme color="#152745" highlightColor="#ffffff">
                      <h4>
                        ₹ <Skeleton width={60} height={27} />
                      </h4>
                    </SkeletonTheme>
                  )}
                  <Link to="/withdrawal">
                    <p>
                      <a href="#">Check Available Balance</a>
                    </p>
                  </Link>
                </div>
              </Card.Body>
            </Card>
            <Card className="cardShadow widthFull marginLeft15">
              <Card.Body>
                <Card.Text className="labelReferral">
                  <strong>Leads Generated</strong>
                </Card.Text>
                <div className="greyBox">
                  {!loader ? (
                    <h4>{leads}</h4>
                  ) : (
                    <SkeletonTheme color="#152745" highlightColor="#ffffff">
                      <h4>
                        {" "}
                        <Skeleton width={60} height={27} />
                      </h4>
                    </SkeletonTheme>
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="align-parallel widthFull">
            <Card className="cardShadow widthFull card">
              <Card.Body
                className="faqBox"
                style={{ padding: "1rem", paddingTop: "2rem" }}
              >
                <div
                  className="align-parallel row"
                  style={{ display: "block"  }}
                >
                  <Card
                    className="cardShadowDash col-sm"
                    style={{ marginRight: "0.5%" }}
                  >
                    <Card.Body className="relative">
                      <Card.Text className="labelReferral">
                        <strong>Referral Link</strong>
                      </Card.Text>
                      {!loader ? (
                        <input
                          className="inputLarge"
                          ref={textAreaRef}
                          value={referallink}
                        />
                      ) : (
                        <Skeleton height={60} radius={20} />
                      )}
                      <img
                        className="copy"
                        onClick={copyToClipboard}
                        src={copy}
                        alt="Copy"
                      />
                    </Card.Body>
                  </Card>
                  <Card
                    className="cardShadowDash col-sm"
                    style={{ marginLeft: "0.5%" }}
                  >
                    <Card.Body className="relative">
                      <Card.Text className="labelReferral">
                        <strong>Referral Code</strong>
                      </Card.Text>
                      {!loadercode ? (
                        <input
                          className="inputLarge"
                          ref={textAreaRefCode}
                          value={referalCode}
                          placeholder="Referraal code"
                        />
                      ) : (
                        <Skeleton height={60} radius={20} />
                      )}
                      <img
                        className="copy"
                        onClick={copyToClipboardCode}
                        src={copy}
                        alt="copy"
                      />
                    </Card.Body>
                  </Card>
                </div>

                <div className="floatLeft leftContent">
                  <Card.Text className="labelReferral">
                    <strong>How it works?</strong>
                  </Card.Text>
                  <ul>
                    <li>
                      Refer PayMe India app to your friends/family members by
                      getting your personalized referral link from ‘View
                      Profile’ section.
                    </li>
                    <li>
                      Once the referee installs the app, ask them to complete
                      the profile.
                    </li>
                    <li>
                      Once the profile is verified by our team, login to this
                      portal and your earning will be reflected on the
                      dashboard.
                    </li>
                    <li>
                      Click on ‘Withdrawal now’ (On dashboard) and enter the
                      amount you wish to withdraw and then click on ‘Request
                      Withdraw’.
                    </li>
                    <li>
                      Kudos! Your cash reward will be credited in your bank
                      account in the next 24 hours.
                    </li>
                    <li>
                      You can check your debit and credit transactions in
                      ‘Earnings’ section.
                    </li>
                    <li>
                      In case of any queries, drop an email to{" "}
                      <a>
                        refer@paymeindia.in
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="floatRight rightContent alignRight">
                  <div className="rewards">
                    <div id="animationRewaed" />
                    <h5>Earn cash reward on successful referral!</h5>
                    <p>Directly in your bank account.</p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  leadWallet,
  getRevenueLeads,
  getReferralCode,
  getUserDetails,
})(Dashboard);
