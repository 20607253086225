/* eslint-disable*/
import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  signInUser,
  savePhoneNumber,
  saveUserToken,
} from "../Redux/ApiCall/action";
import { Link } from "react-router-dom";
import { Container, Card, Button } from "react-bootstrap";
import logo from "../img/logo.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Sidebar.css";
import Cookies from "universal-cookie";
import { Loader } from "./Loader";

const cookies = new Cookies();

toast.configure();

const options = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 8000,
};
const Login = (props) => {
  const [preloader, setPreloader] = useState(true);
  const [userid, setUser] = useState("");
  const [userMpin, setUserMpin] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorMpin, setErrorMpin] = useState("");
  const [errorlogin, setErrorlogin] = useState("");
  const [mpinupdatemsg, setMpinupdatemsg] = useState(
    props.location.state?.detail
  );
  const [toggle, setToggle] = useState("1");
  const [loader, setLoader] = useState("true");
  const notify = (err) => {
    toast.success(mpinupdatemsg, { ...options });
  };
  const [cssname, setCssname] = useState("loginScreen");

  useEffect(() => {
    setPreloader(false);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    console.log("token11111", params);

    if (params) {
      cookies.set("token", params.token);
      let token = params.token;
      console.log("pramod local", token);
      if (token) {
        props.saveUserToken({
          token,
        });

        props.history.push("/dashboard");
      }
    }
  }, []);

  const loginInUser = () => {
    let phone_number = userid;
    if (userid.length < 10) {
      setErrorPhone("Enter 10 digits Phone Number");
      return;
    } else {
      setErrorPhone("");
      props.savePhoneNumber({
        phone_number,
      });
    }
    if (userMpin.length < 6) {
      setErrorMpin("Enter 6 digit MPIN");
      return;
    }
    setCssname("loginScreenBlur");
    setLoader("");

    let mpin = parseInt(userMpin);
    let data = {
      phone_number,
      mpin,
    };

    props
      .signInUser(data)
      .then((res) => {
        setLoader("true");
        setErrorlogin(res.data.error);
        if (res.status === 200) {
          setToggle("2");
          let token = res.data.data.token;
          props.saveUserToken({
            token,
          });
          

          props.history.push("/dashboard");
        }
      })
      .catch((error) => {
        let err = error.response?.data.error;
        // console.log(err.data.error)

        notify(err);
        // Notification(error)
      });
  };

  return (
    <>
      {!loader ? (
        <Loader
          type="Oval"
          color="#152745"
          height={50}
          width={50}
          Number={10000}
        />
      ) : null}
      <div className="blue-bg">
        <Card className={cssname}>
          <Card.Body className="loginScreenPadding">
            {mpinupdatemsg ? (
              <>
                {notify()} {setMpinupdatemsg("")}
              </>
            ) : null}
            <Card.Img
              style={{
                width: "auto",
                height: 70,
                margin: "auto",
              }}
              src="https://wwwstatic-prod.s3-accelerate.amazonaws.com/images/logo.svg"
              alt="Paymeindia"
            />
            <Card.Title className="labelTitleBox">
              PayMe Enhanced Referral Program <br />
              (PERP)
            </Card.Title>
            <label htmlFor="userid" className="labelText">
              Phone Number
            </label>
            <input
              className="inputField"
              id="userid"
              name="uesrid"
              type="text"
              placeholder="Enter Phone Number"
              value={userid}
              onChange={(e) => {
                if (
                  e.target.value.length === 10 ||
                  e.target.value.length === 0
                ) {
                  setErrorPhone("");
                }
                if (e.target.value.match(/^[0-9]+$/)) {
                  setUser(e.target.value.slice(0, 10));
                } else if (e.target.value.length === 0) {
                  setUser(e.target.value);
                }
              }}
            />
            {errorPhone ? (
              <span className="errorMessage">{errorPhone}</span>
            ) : null}
            <br />

            <label id="userid" className="labelText">
              Enter 6 digit Password
            </label>
            <input
              maxLength={6}
              type="password"
              className="inputField"
              id="password"
              name="password"
              placeholder="Password"
              value={userMpin}
              onChange={(e) => {
                if (e.target.value.length === 6) {
                  setErrorMpin("");
                }

                setUserMpin(e.target.value.slice(0, 6));
              }}
            />
            {errorMpin ? (
              <span className="errorMessage">{errorMpin}</span>
            ) : null}
            {errorlogin ? (
              <span className="errorMessage">{errorlogin}</span>
            ) : null}
            <Button className="btnNormal" onClick={loginInUser}>
              Log in
            </Button>

            <Link to="/forgot" className="forgotLink">
              Forgot Password ?
            </Link>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  signInUser,
  savePhoneNumber,
  saveUserToken,
})(Login);
